import React, { useEffect, useState, useContext } from "react";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue, set } from "firebase/database";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { YearContext } from '../contexts/YearContext';

function PredictionsTable() {
  const [teams, setTeams] = useState([]);
  const [user, setUser] = useState(null);
  const { seasonYear, nextYear, submitDate } = useContext(YearContext);

  const formattedSubmitDate = submitDate
    ? new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric' }).format(submitDate) + 'th'
    : '';

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const newTeams = Array.from(teams);
    const [removed] = newTeams.splice(result.source.index, 1);
    newTeams.splice(result.destination.index, 0, removed);
    setTeams(
      newTeams.map((team, index) => ({
        ...team,
        predictedPosition: index + 1,
      }))
    );
  };

  const handleSavePredictions = () => {
    if (!user) return;
    const db = getDatabase();
    const predRef = ref(db, `predictions/${seasonYear}-${nextYear}/${user.displayName}/table`);
    const updatedTeams = teams.sort((a, b) => a.predictedPosition - b.predictedPosition);
    const teamData = updatedTeams.reduce((acc, team) => {
      acc[team.id] = { ...team, predictedPosition: team.predictedPosition };
      return acc;
    }, {});

    set(predRef, teamData)
      .then(() => {
        window.alert("Table has been updated!");
      })
      .catch((error) => {
        console.error("Error updating table:", error);
      });
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (!user || !seasonYear || !nextYear) return;
    const db = getDatabase();
    const predRef = ref(db, `predictions/${seasonYear}-${nextYear}/${user.displayName}/table`);

    const unsubscribe = onValue(predRef, (snapshot) => {
      if (snapshot.exists()) {
        const teamsData = snapshot.val();
        const teamsList = Object.keys(teamsData).map((teamId) => ({
          id: teamId,
          ...teamsData[teamId],
        }));
        teamsList.sort((a, b) => a.predictedPosition - b.predictedPosition);
        setTeams(teamsList);
      } else {
        console.log("No data available");
      }
    });

    return () => unsubscribe();
  }, [user, seasonYear, nextYear]);

  if (!user) {
    return <div>Loading...</div>;
  }

  if (teams.length === 0) {
    return <div>No teams available for prediction.</div>;
  }

  return (
    <div className="container mx-auto mt-5">
      <h1 className="text-center mb-4 text-2xl font-bold">Table Predictions</h1>
      <div className="p-4">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="teams">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps} className="space-y-4">
                {teams.map((team, index) => (
                  <Draggable key={team.id} draggableId={team.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="flex items-center p-4 bg-white bg-opacity-5 backdrop-blur-lg rounded-lg shadow-md space-x-4"
                        >
                        <span className="text-xl font-bold">{index + 1}</span>
                        <img
                          src={team.logo}
                          alt={team.teamName}
                          className="w-10 h-10"
                        />
                        <span className="text-lg">{team.teamName}</span>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="text-center mt-4">
        <button
          type="button"
          className="bg-green-500 text-white px-4 py-2 rounded-md mr-2 hover:bg-green-600"
          onClick={handleSavePredictions}
        >
          Save Predictions
        </button>
        <button
          type="button"
          className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
          onClick={() => window.location.reload()}
        >
          Discard Changes
        </button>
      </div>
      <div className="text-center mt-4">
        <p className="text-gray-500">*You will be able to submit changes to your table up to {formattedSubmitDate}</p>
      </div>
    </div>
  );
}

export default PredictionsTable;
