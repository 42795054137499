import React, { useState, useEffect, useContext } from "react";
import { getDatabase, ref, onValue, get, off } from "firebase/database";
import { auth } from "../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { YearContext } from "../contexts/YearContext";

function TableComps() {
  const [tableNames, setTableNames] = useState([]);
  const [tableMetadata, setTableMetadata] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth); // Track screen width
  const [user] = useAuthState(auth);
  const { seasonYear, nextYear } = useContext(YearContext);
  const [activeTableIndex, setActiveTableIndex] = useState(0);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const db = getDatabase();
    const tablesRef = ref(db, `predictions/${seasonYear}-${nextYear}`);

    const tablesListener = (snapshot) => {
      if (snapshot.exists()) {
        const tableNames = Object.keys(snapshot.val());
        setTableNames(tableNames);

        const tableMetadataPromises = tableNames.map((tableName) => {
          const tableRef = ref(
            db,
            `predictions/${seasonYear}-${nextYear}/${tableName}`
          );
          return get(tableRef);
        });

        Promise.all(tableMetadataPromises).then((tableMetadataSnapshots) => {
          const tableMetadata = tableMetadataSnapshots.map((snapshot) => {
            const tableData = snapshot.val().table;
            const totalScore = snapshot.val().totalScore;
            const sortedTeams = Object.values(tableData)
              .map((team) => ({
                ...team,
                gamesPlayed: team.played,
              }))
              .sort((a, b) => a.predictedPosition - b.predictedPosition);
            return { teams: sortedTeams, totalScore };
          });
          setTableMetadata(tableMetadata);
        });
      }
    };

    onValue(tablesRef, tablesListener);

    return () => {
      off(tablesRef, tablesListener);
    };
  }, [seasonYear, nextYear]);

  const getPlacementClass = (placementPoints) => {
    switch (placementPoints) {
      case 5:
        return "bg-green-500 text-white";
      case 3:
        return "bg-blue-500 text-white";
      case 1:
        return "bg-gray-500 text-white";
      case 8:
        return "bg-red-500 text-white";
      case 10:
        return "bg-yellow-500 text-white";
      case 4:
        return "bg-pink-500 text-white";
      case 6:
        return "bg-purple-500 text-white";
      default:
        return "";
    }
  };

  const rotateTables = (direction) => {
    setActiveTableIndex((prevIndex) => {
      if (direction === "left") {
        return prevIndex === 0 ? tableNames.length - 1 : prevIndex - 1;
      } else if (direction === "right") {
        return (prevIndex + 1) % tableNames.length;
      }
      return prevIndex;
    });
  };

  // Fetch live standings data
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const db = getDatabase();
    const standingsRef = ref(db, `standings/${seasonYear}/pl`);

    get(standingsRef).then((snapshot) => {
      if (snapshot.exists()) {
        const standings = snapshot.val();
        const teams = Object.keys(standings).map((teamId) => ({
          id: teamId,
          ...standings[teamId],
        }));
        teams.sort((a, b) => a.rank - b.rank);
        setTeams(teams);
      } else {
        console.log("No data available");
      }
    });
  }, [seasonYear]);

  return (
    <>
      {user ? (
        <div>
        <div className="flex flex-col lg:flex-row items-center justify-center gap-8 p-4">
          <div className="w-full lg:w-1/2">
            <h2 className="text-2xl font-semibold text-center mb-4">
              Live Standings
            </h2>
            <div className="hidden lg:block lg:h-4"></div>
            <div className="overflow-x-auto">
              <table className="table table-zebra w-full bg-base-100 shadow-lg rounded-lg">
                <thead className="bg-neutral text-neutral-content">
                  <tr>
                    <th className="py-2 px-4 text-left">Rank</th>
                    <th className="py-2 px-4"></th>
                    <th className="py-2 px-4 text-left">Team</th>
                    <th className="py-2 px-4 text-left">{screenWidth < 768 ? "Played" : "Games Played"}</th>
                    <th className="py-2 px-4 text-left">Points</th>
                  </tr>
                </thead>
                <tbody>
                  {teams.map((team) => (
                    <tr key={team.id} className="hover:bg-neutral-focus">
                      <td className="py-2 px-4">{team.rank}</td>
                      <td className="py-2 px-4">
                        <img
                          src={team.logo}
                          alt={team.teamName}
                          className="w-8 h-8 sm:w-6 sm:h-6 md:w-8 md:h-8 rounded-full"
                        />
                      </td>
                      <td className="py-2 px-4">
                        {screenWidth < 768 ? team.abbr : team.teamName}
                      </td>
                      <td className="py-2 px-4">{team.played}</td>
                      <td className="py-2 px-4">{team.points}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            {tableNames.map((table, index) => (
              <div
                key={index}
                className={`transition-all duration-500 ${
                  index === activeTableIndex ? "block" : "hidden"
                }`}
              >
                <div className="flex items-center justify-center mb-4">
                  <button
                    onClick={() => rotateTables("left")}
                    className="btn btn-ghost"
                  >
                    ❮
                  </button>
                  <h2 className="text-2xl font-semibold text-center mx-4">
                    {table} | {tableMetadata[index]?.totalScore} Points
                  </h2>
                  <button
                    onClick={() => rotateTables("right")}
                    className="btn btn-ghost"
                  >
                    ❯
                  </button>
                </div>
                {tableMetadata[index] && (
                  <div className="overflow-x-auto">
                    <table className="table w-full border-collapse border-spacing-0 shadow-lg rounded-lg">
                      <thead className="bg-neutral text-neutral-content">
                        <tr>
                          <th className="py-2 px-4 text-left">
                            {screenWidth < 768 ? "Pred" : "Prediction"}
                          </th>
                          <th></th>
                          <th className="py-2 px-4 text-left">Team</th>
                          <th className="py-2 px-4 text-left">{screenWidth < 768 ? "Played" : "Games Played"}</th>
                          <th className="py-2 px-4 text-left">Score</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableMetadata[index].teams.map((team) => (
                          <tr
                            key={team.id}
                            className={`hover:bg-neutral-focus ${getPlacementClass(
                              team.placementPoints
                            )}`}
                          >
                            <td className="py-2 px-4">
                              {team.predictedPosition}
                            </td>
                            <td className="py-2 px-4">
                              <img
                                src={team.logo}
                                alt={team.teamName}
                                className="w-8 h-8 sm:w-6 sm:h-6 md:w-8 md:h-8 rounded-full"
                              />
                            </td>
                            <td className="py-2 px-4">
                              {screenWidth < 768 ? team.abbr : team.teamName}
                            </td>
                            <td className="py-2 px-4">{team.gamesPlayed}</td>
                            <td className="py-2 px-4">
                              {team.placementPoints}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        </div>
      ) : (
        <div className="text-center py-8">Please sign in to view the tables.</div>
      )}
    </>
  );
}

export default TableComps;
