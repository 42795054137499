import React, { useEffect, useState, useContext, useCallback } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, getDatabase, ref, get } from "../services/firebase";
import { YearContext } from "../contexts/YearContext";
import { FaTrophy } from "react-icons/fa";

function PreviousStandings() {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [previousYearsData, setPreviousYearsData] = useState([]);

  const { seasonYear, nextYear } = useContext(YearContext);

  const fetchAllSeasonsData = useCallback(async () => {
    const db = getDatabase();
    const predictionsRef = ref(db, "predictions");

    // Get all season paths (e.g., "2023-2024", "2022-2023")
    const snapshot = await get(predictionsRef);
    if (snapshot.exists()) {
      const allSeasons = Object.keys(snapshot.val()); // Array of season paths

      const previousSeasonData = [];

      // Loop through each season path and fetch data
      for (const season of allSeasons) {
        // Skip the current season
        if (season === `${seasonYear}-${nextYear}`) continue;

        const seasonRef = ref(db, `predictions/${season}`);
        const seasonSnapshot = await get(seasonRef);

        if (seasonSnapshot.exists()) {
          const tableNames = Object.keys(seasonSnapshot.val());
          const tableMetadataPromises = tableNames.map((tableName) => {
            const tableRef = ref(db, `predictions/${season}/${tableName}`);
            return get(tableRef);
          });

          const tableMetadataSnapshots = await Promise.all(
            tableMetadataPromises
          );
          const tableMetadata = tableMetadataSnapshots.map((snapshot) => {
            const totalScore = snapshot.val().totalScore;
            return { name: snapshot.key, totalScore };
          });

          // Sort scores in descending order
          tableMetadata.sort((a, b) => b.totalScore - a.totalScore);

          // Store the season data
          previousSeasonData.push({
            year: season,
            data: tableMetadata,
          });
        }
      }

      // Sort the seasons in descending order (newest first)
      previousSeasonData.sort((a, b) => {
        const [startA, endA] = a.year.split("-").map(Number);
        const [startB, endB] = b.year.split("-").map(Number);
        return startB - startA || endB - endA;
      });

      // Update state with previous seasons' data
      setPreviousYearsData(previousSeasonData);
    }
  }, [seasonYear, nextYear]);

  useEffect(() => {
    fetchAllSeasonsData(); // Fetch all season data on mount
  }, [fetchAllSeasonsData]);

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
  }, [user, loading, navigate]);

  return (
    <>
      <div className="container mx-auto p-4">
        <h1 className="text-4xl font-bold text-center mb-8">
          Previous Seasons
        </h1>
        {/* Render Previous Years Data */}
        {previousYearsData.length > 0 ? (
          previousYearsData.map((yearData) => {
            const [startYear] = yearData.year.split("-");
            const isPre2021 = parseInt(startYear) < 2021;
            return (
              <div
                key={yearData.year}
                className="mb-8 shadow-lg rounded-lg overflow-x-auto p-4"
              >
                <h2 className="text-2xl font-semibold mb-4">
                  {yearData.year}
                  {isPre2021 && "*"}
                </h2>
                <div className="overflow-x-auto">
                  <table
                    className="table table-zebra w-full"
                    style={{ tableLayout: "fixed", width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "70%" }}>User Name</th>
                        <th style={{ width: "30%" }}>Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      {yearData.data.map((metadata, index) => (
                        <tr
                          key={metadata.name}
                          className={`${
                            index === 0 ? "font-bold text-green-600" : ""
                          }`}
                        >
                          <td>
                            {index === 0 && (
                              <FaTrophy className="inline mr-2" />
                            )}
                            {metadata.name}
                          </td>
                          <td>{metadata.totalScore}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-center">Loading previous seasons data...</div>
        )}
        {/* Footnote */}
        <div className="text-center mt-8 text-sm text-gray-500">
          * Different scoring system was in place prior to the 2021-2022 season.
        </div>
      </div>
    </>
  );
}

export default PreviousStandings;
