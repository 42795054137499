import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { fetchWeeklyScores } from "../services/firebase";

const WeeklyProgressChart = ({ showingProjected }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const loadWeeklyScores = async () => {
      const weeklyScores = await fetchWeeklyScores();

      const transformedData = transformScores(weeklyScores, showingProjected);
      setChartData(transformedData);
    };

    loadWeeklyScores();
  }, [showingProjected]);

  const transformScores = (weeklyScores, isProjected) => {
    const users = {};

    weeklyScores.forEach((week) => {
      const weekData = week.scores;
      Object.keys(weekData).forEach((userId) => {
        if (!users[userId]) {
          users[userId] = {
            name: userId,
            data: [],
          };
        }
        users[userId].data.push(
          isProjected ? weekData[userId].projectedScore : weekData[userId].totalScore
        );
      });
    });

    return Object.values(users).sort((a, b) => a.name.localeCompare(b.name));
  };

  const chartOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: { show: false },
    },
    stroke: { curve: "smooth", width: 3 },
    grid: {
      borderColor: "#e7e7e7",
    },
    xaxis: {
      categories:
        chartData.length > 0
          ? Array(chartData[0].data.length)
              .fill()
              .map((_, i) => `Week ${i + 1}`)
          : [],
      labels: {
        style: {
          colors: "#6B7280",
          fontSize: "12px",
          fontFamily: "Inter, sans-serif",
        },
      },
      axisTicks: {
        color: "#6B7280",
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 5,
      labels: {
        style: {
          colors: "#6B7280",
          fontSize: "12px",
          fontFamily: "Inter, sans-serif",
        },
      },
    },
    legend: {
      show: true,
      labels: {
        colors: "#6B7280",
        fontSize: "14px",
        fontFamily: "Inter, sans-serif",
      },
    },
    dataLabels: { enabled: false },
    tooltip: {
      theme: "dark",
    },
  };

  return (
    <div className="max-w-lg mx-auto p-4 rounded-lg shadow">
      <Chart options={chartOptions} series={chartData} type="line" height={350} />
    </div>
  );
};

export default WeeklyProgressChart;
