import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logout, firestore } from "../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { YearContext } from "../contexts/YearContext";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { FaPaintBrush } from "react-icons/fa";

function Navbar() {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const { submitDate } = useContext(YearContext);
  const [, setTheme] = useState(localStorage.getItem("theme") || "light");

  useEffect(() => {
    const fetchUserTheme = async () => {
      if (user) {
        const userDocRef = doc(firestore, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          const savedTheme = userDocSnapshot.data().theme;
          if (savedTheme) {
            setTheme(savedTheme);
            document.documentElement.setAttribute("data-theme", savedTheme);
          }
        }
      }
    };

    fetchUserTheme();
  }, [user]);

  const handleLogout = () => {
    logout();
    navigate("/");
    closeDrawer();
  };

  const closeDrawer = () => {
    document.getElementById("my-drawer-3").checked = false;
  };

  const changeTheme = async (newTheme) => {
    setTheme(newTheme);
    document.documentElement.setAttribute("data-theme", newTheme);
    localStorage.setItem("theme", newTheme); // Save theme to localStorage

    if (user) {
      const userDocRef = doc(firestore, "users", user.uid);
      await updateDoc(userDocRef, { theme: newTheme }); // Save theme to Firestore
    }
  };

  const currentDate = new Date();

  return (
    <div className="drawer">
      <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content flex flex-col">
        {/* Navbar */}
        <div className="navbar bg-neutral text-neutral-content shadow-lg w-full">
          <div className="flex-none lg:hidden">
            <label
              htmlFor="my-drawer-3"
              aria-label="open sidebar"
              className="btn btn-square btn-ghost"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block h-6 w-6 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </label>
          </div>
          <div className="flex-1 flex items-center">
            <Link to="/" className="btn btn-ghost normal-case text-xl">
              PremPicks
            </Link>
            <ul className="menu menu-horizontal p-0 ml-4 hidden lg:flex items-center">
              <li>
                <Link to="/" className="hover:bg-neutral-focus">
                  Home
                </Link>
              </li>
              {currentDate < submitDate ? (
                <li>
                  <Link to="/predictions" className="hover:bg-neutral-focus">
                    Table Predictions
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/compare" className="hover:bg-neutral-focus">
                    Table Comparisons
                  </Link>
                </li>
              )}
              <li>
                <Link
                  to="/projected"
                  className="hover:bg-neutral-focus"
                >
                  * Projected Score *
                </Link>
              </li>
              <li>
                <Link
                  to="/previous-standings"
                  className="hover:bg-neutral-focus"
                >
                  Previous Standings
                </Link>
              </li>
              <li>
                <Link to="/scoring" className="hover:bg-neutral-focus">
                  Scoring
                </Link>
              </li>
              <div className="dropdown z-50">
                <label tabIndex={0} className="btn btn-ghost">
                  <FaPaintBrush className="text-base" /> {/* Paintbrush Icon */}
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content menu shadow bg-base-100 rounded-box w-52"
                >
                  <li>
                    <button onClick={() => changeTheme("light")}>Light</button>
                  </li>
                  <li>
                    <button onClick={() => changeTheme("dark")}>Dark</button>
                  </li>
                  <li>
                    <button onClick={() => changeTheme("synthwave")}>
                      Synthwave
                    </button>
                  </li>
                  <li>
                    <button onClick={() => changeTheme("night")}>Night</button>
                  </li>
                </ul>
              </div>
            </ul>
          </div>
          <div className="flex-none hidden lg:flex">
            <ul className="menu menu-horizontal p-0">
              <li>
                {user ? (
                  <button
                    onClick={handleLogout}
                    className="hover:bg-neutral-focus"
                  >
                    Logout
                  </button>
                ) : (
                  <Link to="/" className="hover:bg-neutral-focus">
                    Login
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
        {/* Page content here */}
        <div className="p-4">{/* Your page content goes here */}</div>
      </div>
      <div className="drawer-side z-50 lg:hidden">
        <label
          htmlFor="my-drawer-3"
          aria-label="close sidebar"
          className="drawer-overlay"
        ></label>
        <ul className="menu bg-neutral text-neutral-content min-h-full w-80 p-4">
          <li>
            <Link to="/" onClick={closeDrawer}>
              Home
            </Link>
          </li>
          {currentDate < submitDate ? (
            <li>
              <Link to="/predictions" onClick={closeDrawer}>
                Table Predictions
              </Link>
            </li>
          ) : (
            <li>
              <Link to="/compare" onClick={closeDrawer}>
                Table Comparisons
              </Link>
            </li>
          )}
          <li>
            <Link
              to="/projected"
              className="hover:bg-neutral-focus"
            >
              * Projected Score *
            </Link>
          </li>
          <li>
            <Link to="/previous-standings" onClick={closeDrawer}>
              Previous Standings
            </Link>
          </li>
          <li>
            <Link to="/scoring" onClick={closeDrawer}>
              Scoring
            </Link>
          </li>
          <li>
            {user ? (
              <button onClick={handleLogout} className="w-full text-left">
                Logout
              </button>
            ) : (
              <Link to="/" className="w-full text-left" onClick={closeDrawer}>
                Login
              </Link>
            )}
          </li>
          {/* Theme Options in Drawer */}
          <div className="dropdown w-full z-50">
            <label tabIndex={0} className="btn btn-ghost">
              <FaPaintBrush className="text-2xl" /> {/* Paintbrush Icon */}
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content menu shadow bg-base-100 rounded-box w-52"
            >
              <li>
                <button onClick={() => changeTheme("light")}>Light</button>
              </li>
              <li>
                <button onClick={() => changeTheme("dark")}>Dark</button>
              </li>
              <li>
                <button onClick={() => changeTheme("synthwave")}>
                  Synthwave
                </button>
              </li>
              <li>
                <button onClick={() => changeTheme("night")}>Night</button>
              </li>
            </ul>
          </div>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
