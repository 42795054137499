import React from "react";

function Scoring() {
  return (
    <>
      <div className="my-8"></div>
      <h1 className="text-4xl font-bold text-center mb-8">Scoring System</h1>
      <div className="flex justify-center">
        <div className="overflow-x-auto max-w-4xl w-full">
          <table className="table-auto border-collapse w-full shadow-lg">
            <thead>
              <tr className="bg-primary text-white text-lg">
                <th className="p-4">Category</th>
                <th className="p-4">Points</th>
                <th className="p-4">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-green-600 text-white">
                <td className="text-center font-bold p-4">GOAL</td>
                <td className="text-center font-bold p-4">5</td>
                <td className="p-4">
                  If you predict a team's position perfectly, that is, your
                  predicted position matches the team's actual end-of-season
                  rank.
                </td>
              </tr>
              <tr className="bg-blue-600 text-white">
                <td className="text-center font-bold p-4">ASSIST</td>
                <td className="text-center font-bold p-4">3</td>
                <td className="p-4">
                  If your prediction is off by just 1 position, for example, if
                  you predict a team to finish 3rd, but they finish 2nd or 4th.
                </td>
              </tr>
              <tr className="bg-gray-600 text-white">
                <td className="text-center font-bold p-4">WOODWORK</td>
                <td className="text-center font-bold p-4">1</td>
                <td className="p-4">
                  If your prediction is off by 2 positions, for example, if you
                  predict a team to finish 3rd, but they finish either 1st or
                  5th.
                </td>
              </tr>
              <tr className="bg-yellow-500 text-white">
                <td className="text-center font-bold p-4">CHAMPION</td>
                <td className="text-center font-bold p-4">5 (bonus)</td>
                <td className="p-4">
                  If you correctly predict the team that finishes 1st, you'll
                  receive this additional bonus on top of the points earned from
                  the exact match.
                </td>
              </tr>
              <tr className="bg-pink-600 text-white">
                <td className="text-center font-bold p-4">RELEGATED</td>
                <td className="text-center font-bold p-4">3 (bonus)</td>
                <td className="p-4">
                  If you correctly predict a team that will end up in any of the
                  bottom three positions (18th, 19th, or 20th), you'll earn this
                  additional bonus on top of your usual score.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="max-w-4xl mx-auto mt-8 p-4 text-lg">
        <p>
          Your total score will be the sum of all your points from every
          prediction.
        </p>
        <h2 className="text-2xl font-semibold mt-8">Tie-Break</h2>
        <p className="mt-4">
          In the event of a tie at the end of the Premier League Season, the
          contestants must submit a predicted FA Cup winner and margin of
          victory to the commissioner no later than 24 hours prior to the FA Cup
          Final.
        </p>
        <div className="my-8"></div>
      </div>
    </>
  );
}

export default Scoring;
