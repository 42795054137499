// src/contexts/YearProvider.js
import React, { useState, useEffect } from "react";
import { YearContext } from "./YearContext"; // Import YearContext

export function YearProvider({ children }) {
  const [seasonYear, setSeasonYear] = useState(null);
  const [nextYear, setNextYear] = useState(null);
  const [submitDate, setSubmitDate] = useState(null);

  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Month is zero-based

    let season;
    if (currentMonth >= 1 && currentMonth <= 6) {
      // January to June, use previous year
      season = currentYear - 1;
    } else if (currentMonth >= 7 && currentMonth <= 12) {
      // July to December, use current year
      season = currentYear;
    }

    const next = season + 1;

    // Set reveal date as August 30th of the next season
    const submit = new Date(`${season}-08-30`);

    setSeasonYear(season);
    setNextYear(next);
    setSubmitDate(submit);
  }, []);

  return (
    <YearContext.Provider value={{ seasonYear, nextYear, submitDate }}>
      {children}
    </YearContext.Provider>
  );
}
