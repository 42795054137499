import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  auth,
  fetchTeamData,
  fetchUserTitles,
  saveFavoriteTeam,
} from "../services/firebase";
import { getDatabase, ref, get } from "firebase/database";
import { useAuthState } from "react-firebase-hooks/auth";
import TeamSelectionModal from "../components/TeamSelectionModal";
import { YearContext } from "../contexts/YearContext";
import trophyImage from "../assets/trophy.png";
import WeeklyProgressChart from "../components/WeeklyProgressChart";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const HomePage = () => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const { submitDate, seasonYear, nextYear } = useContext(YearContext);
  const [favoriteTeam, setFavoriteTeam] = useState(null);
  const [teamLogo, setTeamLogo] = useState("");
  const [titles, setTitles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userScores, setUserScores] = useState([]);
  const [projectedScores, setProjectedScores] = useState([]);
  const [showingProjected, setShowingProjected] = useState(false);

  useEffect(() => {
    if (loading) return;
    if (!user) {
      navigate("/login");
      return;
    }

    const loadUserData = async () => {
      if (user) {
        const { favoriteTeam, favoriteTeamLogo } = await fetchTeamData(user);
        setFavoriteTeam(favoriteTeam);

        if (!favoriteTeam) {
          setIsModalOpen(true);
        } else {
          setTeamLogo(favoriteTeamLogo);
        }

        const userTitles = await fetchUserTitles(user);
        setTitles(userTitles || []);
      }
    };

    loadUserData();
  }, [user, loading, navigate]);

  useEffect(() => {
    if (new Date() > submitDate) {
      const db = getDatabase();
      const predictionsRef = ref(db, `predictions/${seasonYear}-${nextYear}`);

      get(predictionsRef).then((snapshot) => {
        if (snapshot.exists()) {
          const scoresData = snapshot.val();
          const scores = Object.keys(scoresData).map((username) => ({
            username,
            totalScore: scoresData[username].totalScore || 0,
            projectedScore: scoresData[username].projectedScore || 0,
          }));

          scores.sort((a, b) => b.totalScore - a.totalScore);
          setUserScores(scores);

          const projected = [...scores].sort(
            (a, b) => b.projectedScore - a.projectedScore
          );
          setProjectedScores(projected);
        }
      });
    }
  }, [submitDate, seasonYear, nextYear]);

  const toggleScores = () => {
    setShowingProjected((prev) => !prev);
  };

  const handleNavigation = () => {
    const currentDate = new Date();
    if (currentDate < submitDate) {
      navigate("/predictions");
    } else {
      navigate("/compare");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-base-200">
      <h1 className="text-4xl font-bold mb-6 text-center">
        Welcome to PremPicks
      </h1>
      {user ? (
        <>
          {favoriteTeam && (
            <img
              src={teamLogo}
              alt={favoriteTeam}
              className="w-32 h-32 mb-4 shadow-lg"
            />
          )}
          <h2 className="text-2xl mb-4 text-center">
            {user.displayName || user.email}
          </h2>

          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="flex space-x-4">
                {titles.length > 0 ? (
                  titles.map((year) => (
                    <div key={year} className="flex flex-col items-center">
                      <img
                        src={trophyImage}
                        alt="Trophy"
                        className="w-16 h-16"
                      />
                      <span className="text-lg mt-2">{year}</span>
                    </div>
                  ))
                ) : (
                  <span>THIS IS YOUR YEAR!</span>
                )}
              </div>
            </div>
          </div>

          {new Date() > submitDate && (
            <div className="mt-8">
              {/* Header with Chevron Controls */}
              <div className="flex items-center justify-center mb-4">
                <button
                  className="btn btn-ghost mr-2"
                  onClick={toggleScores}
                >
                  <FaChevronLeft />
                </button>
                <h3 className="text-2xl font-semibold text-center">
                  {showingProjected ? "Projected Scores" : "Current Scores"}
                </h3>
                <button
                  className="btn btn-ghost ml-2"
                  onClick={toggleScores}
                >
                  <FaChevronRight />
                </button>
              </div>

              {/* Grid Container for Table and Chart */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Scores Table */}
                <div>
                  <div className="p-5 overflow-x-auto">
                    <table className="table table-zebra w-full bg-base-100 shadow-lg rounded-lg">
                      <thead className="bg-neutral text-neutral-content">
                        <tr>
                          <th className="py-2 px-4 text-left">Rank</th>
                          <th className="py-2 px-4 text-left">Username</th>
                          <th className="py-2 px-4 text-left">
                            {showingProjected
                              ? "Projected Score"
                              : "Total Score"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(showingProjected ? projectedScores : userScores).map(
                          (score, index) => (
                            <tr
                              key={score.username}
                              className="hover:bg-neutral-focus"
                            >
                              <td className="py-4 px-4">{index + 1}</td>
                              <td className="py-4 px-4">{score.username}</td>
                              <td className="py-4 px-4">
                                {showingProjected
                                  ? score.projectedScore
                                  : score.totalScore}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Chart */}
                <div>
                  <WeeklyProgressChart showingProjected={showingProjected} />
                </div>
              </div>
            </div>
          )}
          <button onClick={handleNavigation} className="btn btn-primary mt-8">
            {new Date() < submitDate ? "Edit Table Predictions" : "Show Tables"}
          </button>
        </>
      ) : null}

      {isModalOpen && (
        <TeamSelectionModal
          user={user}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSelectTeam={async (teamName, teamLogo) => {
            if (teamName && teamLogo) {
              await saveFavoriteTeam(user, teamName, teamLogo);
              setFavoriteTeam(teamName);
              setTeamLogo(teamLogo);
              setIsModalOpen(false);
            }
          }}
        />
      )}
    </div>
  );
};

export default HomePage;
