import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
} from "../services/firebase";
import { YearContext } from "../contexts/YearContext";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const navigate = useNavigate();
  const { seasonYear, nextYear } = useContext(YearContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    await logInWithEmailAndPassword(email, password, seasonYear, nextYear);
    navigate("/");
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    await registerWithEmailAndPassword(
      name,
      email,
      password,
      seasonYear,
      nextYear
    );
    navigate("/");
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    await sendPasswordReset(email);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-base-200">
      {!isResetting ? (
        <form
          onSubmit={isRegistering ? handleRegister : handleLogin}
          className="card w-full max-w-md shadow-lg bg-base-100"
        >
          <div className="card-body">
            <h2 className="card-title text-center">
              {isRegistering ? "Register" : "Login"} to PremPicks
            </h2>
            {isRegistering && (
              <div className="form-control">
                <input
                  type="text"
                  className="input input-bordered"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Full Name"
                  required
                />
              </div>
            )}
            <div className="form-control">
              <input
                type="email"
                className="input input-bordered"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail Address"
                required
              />
            </div>
            <div className="form-control">
              <input
                type="password"
                className="input input-bordered"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
              />
            </div>
            <div className="form-control mt-4">
              <button type="submit" className="btn btn-primary">
                {isRegistering ? "Register" : "Login"}
              </button>
            </div>
            <div className="text-center mt-4">
              <button
                type="button"
                className="link link-primary"
                onClick={() => setIsResetting(true)}
              >
                Forgot Password?
              </button>
            </div>
            <div className="text-center mt-4">
              <button
                type="button"
                className="link link-primary"
                onClick={() => setIsRegistering(!isRegistering)}
              >
                {isRegistering
                  ? "Already have an account? Login"
                  : "Don't have an account? Register"}
              </button>
            </div>
          </div>
        </form>
      ) : (
        <form
          onSubmit={handlePasswordReset}
          className="card w-full max-w-md shadow-lg bg-base-100"
        >
          <div className="card-body">
            <h2 className="card-title text-center">Reset Password</h2>
            <div className="form-control">
              <input
                type="email"
                className="input input-bordered"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail Address"
                required
              />
            </div>
            <div className="form-control mt-4">
              <button type="submit" className="btn btn-primary">
                Send Password Reset Email
              </button>
            </div>
            <div className="text-center mt-4">
              <button
                type="button"
                className="link link-primary"
                onClick={() => setIsResetting(false)}
              >
                Back to Login
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default Login;
